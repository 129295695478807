import React from "react";
import Nicole from "assets/images/profileOne.png";
import Ingo from "assets/images/profileTwo.png";
import RightArrow from "assets/images/rightArrow.png";
import "./Contact.scss";

const Contact = () => {
  return (
    <>
      <div className="contact-container">
        <div className="container">
          <div className="title">
            <h2 className="lg">
              Contact <span className="text-dark md"> Us</span>
            </h2>
            <p className="lead text-darkLight">
              Connect with our experts to know more
            </p>
          </div>
          <div className="card-wrapper">
            <div className="info-card">
              <div className="card-image">
                <img src={Nicole} alt="" width={"150"} height={"150"} />
              </div>
              <div className="contact-header">
                <div className="md">Nicole Voigt</div>
                <p className="sm">
                  Managing Director & Partner | Industrial Goods Practice Area
                </p>
              </div>
              <div className="contact-desc">
                <p className="lead">
                  Nicole Voigt is BCG’s Global Topic Lead for Metals and a core
                  member of the Industrial Goods Practice at BCG. She has gained
                  extensive experience helping clients build competitive
                  advantage, focusing on operational excellence and (digital)
                  supply chain transformations in Metals & Mining.
                </p>
              </div>
              <div className="contact-link">
                <a id="link" href="mailto:Voigt.Nicole@bcg.com">
                  Connect with Nicole
                </a>
                <img src={RightArrow} width={"16"} height={"12"} />
              </div>
            </div>

            <div className="info-card">
              <div className="card-image">
                <img src={Ingo} alt="" width={"150"} height={"150"} />
              </div>
              <div className="contact-header">
                <div className="md">Ingo Mergelkamp</div>
                <p className="sm">
                  Knowledge Senior Director | Metals & Mining
                </p>
              </div>
              <div className="contact-desc">
                <p className="lead">
                  Ingo Mergelkamp is a core member of BCG’s Industrial Goods
                  Practice with a special focus on the iron and steelmaking
                  industries. He manages BCG’s Metals & Mining knowledge teams
                  in Dusseldorf and Boston. In the past, Ingo has developed
                  various proprietary tools for the analysis of spreads, steel
                  trade and profit pools.
                </p>
              </div>
              <div className="contact-link">
                <a id="link" href="mailto:Mergelkamp.Ingo@bcg.com">
                  Connect with Ingo
                </a>
                <img src={RightArrow} width={"16"} height={"12"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
