import React, {useState, useEffect} from "react";
import VideoOne from "../../assets/images/videosOne.png";
import VideoTwo from "../../assets/images/videoTwo.png";
import videoThree from "../../assets/images/videoThree.png";
import VideoFour from "../../assets/images/video4.png";
import VideoFive from "../../assets/images/videoFive.png";
import mac from "../../assets/images/mac.png";
import ellipsedesign from "../../assets/images/ellipsedesign.png";
import ellipsedesignmobile from "../../assets/images/ellipsedesignmobile.png";
import "./Videos.scss";

const videosData = [
  {
    id: 1,
    image: VideoTwo,
    title: "Transforming Data<br/> <span>into Actionable Insights</span>",
    description:
      "Intuitive dashboards provide insights to real-life questions, not just raw data.",
    subHeading: "Who is investing in green steel?",
    subTitle:
      "Navigate through Plant Status <br/>Get an overview of global green steel plants <br/>Instantly access plant details <br/>Delve into diverse green steel initiatives",
    video: "https://bcg.widen.net/s/lljgjmmrgh/video_1---geo-analysis---20-jun",
  },
  {
    id: 2,
    image: VideoOne,
    title: "Transforming Data<br/> <span>into Actionable Insights</span>",
    description:
      "Intuitive dashboards provide insights to real-life questions, not just raw data.",
    subHeading: "How is a company set up along value chain?",
    subTitle:
      "Navigate through Plant Status <br/>Get an overview of global green steel plants <br/>Instantly access plant details <br/>Delve into diverse green steel initiatives",
    video: " https://bcg.widen.net/s/2jpcl7wfrs/video_4_-value-chain---20-jun",
  },
  {
    image: videoThree,
    id: 3,
    title: "Transforming Data<br/> <span>into Actionable Insights</span>",
    description:
      "Intuitive dashboards provide insights to real-life questions, not just raw data.",
    subHeading: "How old are the steel facilities in a country?",
    subTitle:
      "Navigate through Plant Status <br/>Get an overview of global green steel plants <br/>Instantly access plant details <br/>Delve into diverse green steel initiatives",
    video: "https://bcg.widen.net/s/hnzxbkcgrf/video_2_-age_profiling_20-jun",
  },
  {
    id: 4,
    image: VideoFour,
    title: "Transforming Data<br/> <span>into Actionable Insights</span>",
    description:
      "Intuitive dashboards provide insights to real-life questions, not just raw data.",
    subHeading: "How do companies compare on strategy(BOF/EAF,long/flat)?",
    subTitle:
      "Navigate through Plant Status <br/>Get an overview of global green steel plants <br/>Instantly access plant details <br/>Delve into diverse green steel initiatives",
    video:
      "https://bcg.widen.net/s/cphpjxhnkf/video_5_-ranking-by-steel-making---20-jun",
  },
  {
    id: 5,
    image: VideoFive,
    title: "Transforming Data<br/> <span>into Actionable Insights</span>",
    description:
      "Intuitive dashboards provide insights to real-life questions, not just raw data.",
    subHeading: "Who can produce high grade electrical steel for EVs?",
    subTitle:
      "Navigate through Plant Status <br/>Get an overview of global green steel plants <br/>Instantly access plant details <br/>Delve into diverse green steel initiatives",
    video:
      "https://bcg.widen.net/s/krqk9qj5lr/video_3_-producer-lookup---20-jun",
  },
];

const Videos = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  useEffect(() => {
    const handleVideoEnd = () => {
      setCurrentVideoIndex((prevIndex) =>
        prevIndex < videosData.length - 1 ? prevIndex + 1 : 0
      );
    };

    const videoPlayer = document.getElementById("video-player");
    if (videoPlayer) {
      videoPlayer.src = videosData[currentVideoIndex].video;
      videoPlayer.autoplay = true;
      videoPlayer.addEventListener("ended", handleVideoEnd);
    }

    return () => {
      if (videoPlayer) {
        videoPlayer.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, [currentVideoIndex]);
  return (
    <div className="video-container">
      <div className="container">
        <div className="video-wrapper">
          <div className="videos-header">
            <h1 className="md">
              Transforming Data
              <br /> into
              <span className="md bold"> Actionable Insights </span>
            </h1>

            <p className="lead">{videosData[currentVideoIndex].description}</p>
            <img
              src={ellipsedesign}
              alt="ellipsedesign"
              className="ellipsedesign"
            />
            <img
              src={ellipsedesignmobile}
              alt="ellipsedesignmobile"
              className="ellipsedesignmobile"
            />
          </div>
          <div className="grey-label-pane">
            <h1 className="smd">{videosData[currentVideoIndex].subHeading}</h1>
            {
              <p className="lead line-hight">
                {videosData[currentVideoIndex].subTitle
                  .split("<br/>")
                  .map((part, index) => (
                    <>
                      {part}
                      <br />
                    </>
                  ))}
              </p>
            }
          </div>
          <div className="video-wrapper-mac">
            <img src={mac} alt="mac" className="mac" />
            <div className="video-player">
              {/* <video
              id="video-player"
              src={videosData[currentVideoIndex].video}
              title={videosData[currentVideoIndex].title}
              autoPlay
              controls
            ></video> */}
              <iframe
                src={videosData[currentVideoIndex].video}
                title={videosData[currentVideoIndex].title}
                frameBorder="0"
                autoPlay
                allow="autoplay; loop"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="imageList-list">
        <div className="images-wrapper">
          {videosData.map((video, index) => (
            <div
              className={`video-image ${
                index === currentVideoIndex ? "active" : ""
              }`}
              key={index}
              onClick={() => setCurrentVideoIndex(index)}
            >
              <img alt={video.title} src={video.image} />
              <p className="lead">{video.subHeading}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Videos;
