import React from "react";
import greensteel from "assets/images/greensteel.png";
import xev from "assets/images/xev.png";
import WindTurbine from "assets/images/WindTurbine.png";
import topsteel from "assets/images/topsteel.png";
import GasFlame from "assets/images/firediya.png";
import globalIcon from "assets/images/golab.png";
import steel_value from "assets/images/steel_value.png";
import blastFurnaces from "assets/images/blastFurnaces.png";
import dataAnalysis from "assets/images/dataAnalysis.png";
import "./Guide.scss";
import usePagination from "hooks/usePagination";
import Pagination from "components/paginationButton/Pagination";

const data = [
  {
    id: 1,
    image: greensteel,
    title: "Upcoming green steel investments",
    description:
      "Use the tool to know about new projects in DRI vs Blast Furnace or BOF vs EAFs.",
  },
  {
    id: 2,
    image: xev,
    title: "xEV grade steel production",
    description:
      "Plantfacts allows you to filter rolling mills capable of producing electrical grade steel.",
  },
  {
    id: 3,
    image: WindTurbine,
    title: "Thick plate for wind turbines",
    description:
      "You can use the grade data to filter out mills producing plates of certain thickness.",
  },
  {
    id: 4,
    image: topsteel,
    title: "Top steel producing companies",
    description:
      "Find the top companies ranked by capacities across region and product type in the tool.",
  },
  {
    id: 5,
    image: GasFlame,
    title: "Blast furnace relining",
    description:
      "Quickly discover the due date for relining using the last relining year of the furnace.",
  },
  {
    id: 6,
    image: globalIcon,
    title: "Geographical spread of companies",
    description:
      "Discover how companies are spread across geographies either by JV status or own operations.",
  },
  {
    id: 7,
    image: steel_value,
    title: "Steel value chain of a company",
    description:
      "Know exactly where a company’s facilities lie across the value chain.",
  },
  {
    id: 8,
    image: blastFurnaces,
    title: "Age structure of plants",
    description:
      "Rank the company, country or region by age of plant using start year data.",
  },
  {
    id: 9,
    image: dataAnalysis,
    title: "Custom data analysis",
    description:
      "You can download the data in an excel format and apply required filters to analyze the data.",
  },
];

const Guide = () => {
  const {
    currentItems,
    currentPage,
    totalPages,
    handleClickPrevious,
    handleClickNext,
  } = usePagination(data, 3);
  return (
    <div className="guide-container">
      <div className="container">
        <div className="guide-title">
          <h1 className="text-light md bold">
            Your Guide to
            <span className="md text-light"> using Plantfacts </span>
          </h1>
          <p className="lead text-light">
            Discover how you can utilize the tool in the best way
          </p>
        </div>
        <div className="guide-wrapper">
          {currentItems.map((item) => (
            <div className="guide-card" key={item.id}>
              <div className="image-container">
                <img src={item.image} alt={item.title} />
              </div>
              <h2 className="smd">{item.title}</h2>
              <p className="lead text-light">{item.description}</p>
            </div>
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleClickPrevious={handleClickPrevious}
          handleClickNext={handleClickNext}
          isLight={true}
        />
      </div>
    </div>
  );
};

export default Guide;
