import React from "react";
import logo from "assets/images/Logo.png";
import email from "assets/images/E-mail.png";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-wrapper">
          <div className="footer-container">
            <div className="email-container">
              <img src={email} />
              <a className="lead text-light" href="mailto:Plantfacts@bcg.com">
                Plantfacts@bcg.com
              </a>
            </div>

            <ul className="footer-links">
              <li className="copyright text-light">
                © 2024 Boston Consulting Group
              </li>
              <li>
                <a className="text-lightDark">|</a>
              </li>
              <li>
                <a
                  href="https://www.bcg.com/en-in/about/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="https://www.bcg.com/en-in/about/terms-of-use"
                  target="_blank"
                >
                  Terms of Use
                </a>
              </li>
              <li>
                <a href="https://www.bcg.com/" target="_blank">
                  BCG.com
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-logo">
            <img src={logo} width={"66"} height={"27"} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
