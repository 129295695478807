import React from "react";
import logo from "assets/images/Logo.png";
import "./Header.scss";
import Button from "components/button/Button";

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="logo" className="logo" />
      </div>
      <div className="btn-container">
        <Button handleClick={() => { }} />
      </div>
    </header>
  );
};

export default Header;
