import React from "react";
import Plantfacts from "assets/images/Plantfacts Logo.svg";
import BannerImage from "assets/images/banner.png";
import ellipse1 from "assets/images/ellipse1.png";
import "./Banner.scss";
import Button from "components/button/Button";
const Banner = () => {
  return (
    <div className="banner-container">
      <img src={ellipse1} className="ellipse1" alt="ellipse1" />
      <div className="banner-image-container">
        <div className="plantfacts-logo">
          <img src={Plantfacts} alt="plantfacts" />
          {/* <svg
            width="302"
            height="93"
            viewBox="0 0 302 93"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.0992 42.54C23.0992 44.388 22.7619 45.9427 22.0872 47.204C21.4126 48.436 20.5326 49.4333 19.4472 50.196C18.3619 50.9587 17.1152 51.5013 15.7072 51.824C14.2992 52.1467 12.8766 52.308 11.4392 52.308H6.59924V63H0.219238V33.08H11.6152C13.1406 33.08 14.5926 33.256 15.9712 33.608C17.3792 33.96 18.6112 34.5173 19.6672 35.28C20.7232 36.0427 21.5592 37.0253 22.1752 38.228C22.7912 39.4013 23.0992 40.8387 23.0992 42.54ZM16.2352 42.584C16.2352 41.7333 16.0886 41 15.7952 40.384C15.5019 39.768 15.0912 39.2693 14.5632 38.888C14.0646 38.4773 13.4632 38.184 12.7592 38.008C12.0552 37.832 11.2926 37.744 10.4712 37.744H6.59924V47.644H10.1192C10.8526 47.644 11.5859 47.5853 12.3192 47.468C13.0526 47.3213 13.7126 47.072 14.2992 46.72C14.8859 46.3387 15.3552 45.8253 15.7072 45.18C16.0592 44.5053 16.2352 43.64 16.2352 42.584Z"
              fill="black"
            />
            <path
              d="M29.2661 63V33.08H35.6461V57.94H48.8021V63H29.2661Z"
              fill="black"
            />
            <path
              d="M72.9268 63L70.4628 56.312H58.9348L56.6028 63H50.1788L61.2668 32.684H68.5708L79.9228 63H72.9268ZM64.6988 39.724H64.5668L60.5628 51.384H68.7908L64.6988 39.724Z"
              fill="black"
            />
            <path
              d="M104.147 63.396L90.6389 43.156H90.5069V63H84.5669V33.08H91.5189L104.191 52.44H104.323V33.08H110.263V63.396H104.147Z"
              fill="black"
            />
            <path
              d="M125.009 63V38.14H115.153V33.08H141.289V38.14H131.389V63H125.009Z"
              fill="black"
            />
            <path
              d="M146.184 63V33.08H165.588V38.14H152.564V45.928H162.816V50.988H152.564V63H146.184Z"
              fill="black"
            />
            <path
              d="M187.181 63L184.717 56.312H173.189L170.857 63H164.433L175.521 32.684H182.825L194.177 63H187.181ZM178.953 39.724H178.821L174.817 51.384H183.045L178.953 39.724Z"
              fill="black"
            />
            <path
              d="M221.237 58.424C220.005 60.272 218.451 61.6067 216.573 62.428C214.696 63.22 212.555 63.616 210.149 63.616C207.861 63.616 205.808 63.264 203.989 62.56C202.171 61.8267 200.616 60.7853 199.325 59.436C198.064 58.0867 197.096 56.4587 196.421 54.552C195.747 52.6453 195.409 50.504 195.409 48.128C195.409 45.6933 195.747 43.508 196.421 41.572C197.096 39.636 198.064 37.9933 199.325 36.644C200.616 35.2947 202.156 34.268 203.945 33.564C205.764 32.8307 207.817 32.464 210.105 32.464C212.305 32.464 214.3 32.86 216.089 33.652C217.908 34.444 219.433 35.764 220.665 37.612L215.869 40.692C215.195 39.7533 214.388 39.0053 213.449 38.448C212.511 37.8907 211.337 37.612 209.929 37.612C207.465 37.612 205.573 38.492 204.253 40.252C202.933 42.012 202.273 44.5493 202.273 47.864C202.273 51.12 202.963 53.7013 204.341 55.608C205.749 57.5147 207.803 58.468 210.501 58.468C211.792 58.468 212.965 58.2187 214.021 57.72C215.077 57.2213 215.943 56.4587 216.617 55.432L221.237 58.424Z"
              fill="black"
            />
            <path
              d="M232.688 63V38.14H222.832V33.08H248.968V38.14H239.068V63H232.688Z"
              fill="black"
            />
            <path
              d="M262.224 63.616C256.709 63.616 252.984 61.5773 251.048 57.5L256.24 55.036C257.002 56.268 257.868 57.1773 258.836 57.764C259.804 58.3507 261.036 58.644 262.532 58.644C266.081 58.644 267.856 57.4707 267.856 55.124C267.856 54.0387 267.46 53.2027 266.668 52.616C265.905 52 264.952 51.4867 263.808 51.076C262.664 50.636 261.402 50.2253 260.024 49.844C258.674 49.4333 257.428 48.9053 256.284 48.26C255.14 47.5853 254.172 46.72 253.38 45.664C252.617 44.608 252.236 43.1853 252.236 41.396C252.236 40.076 252.456 38.8733 252.896 37.788C253.336 36.6733 254.01 35.7347 254.92 34.972C255.829 34.18 256.958 33.564 258.308 33.124C259.686 32.684 261.285 32.464 263.104 32.464C265.274 32.464 267.225 32.7867 268.956 33.432C270.716 34.048 272.168 35.1773 273.312 36.82L268.736 39.9C268.061 39.0493 267.269 38.4333 266.36 38.052C265.45 37.6413 264.277 37.436 262.84 37.436C261.344 37.436 260.214 37.744 259.452 38.36C258.718 38.9467 258.352 39.6947 258.352 40.604C258.352 41.572 258.733 42.3347 259.496 42.892C260.288 43.4493 261.27 43.9333 262.444 44.344C263.617 44.7547 264.878 45.1653 266.228 45.576C267.606 45.9573 268.882 46.5 270.056 47.204C271.229 47.908 272.197 48.8173 272.96 49.932C273.752 51.0467 274.148 52.528 274.148 54.376C274.148 55.96 273.854 57.3387 273.268 58.512C272.681 59.656 271.86 60.6093 270.804 61.372C269.748 62.1347 268.486 62.692 267.02 63.044C265.582 63.4253 263.984 63.616 262.224 63.616Z"
              fill="black"
            />
            <path
              d="M217.959 88.852C217.959 89.5107 217.82 90.0743 217.541 90.543C217.262 90.999 216.882 91.3727 216.401 91.664C215.932 91.9553 215.388 92.1707 214.767 92.31C214.146 92.4367 213.488 92.5 212.791 92.5H207.395V79.58H212.05C212.658 79.58 213.247 79.637 213.817 79.751C214.4 79.8523 214.906 80.036 215.337 80.302C215.78 80.5553 216.135 80.891 216.401 81.309C216.667 81.7143 216.8 82.221 216.8 82.829C216.8 83.589 216.591 84.197 216.173 84.653C215.768 85.0963 215.223 85.4383 214.539 85.679V85.717C215.046 85.7803 215.508 85.888 215.926 86.04C216.344 86.1793 216.705 86.382 217.009 86.648C217.313 86.9013 217.547 87.2117 217.712 87.579C217.877 87.9337 217.959 88.358 217.959 88.852ZM214.995 88.662C214.995 88.054 214.754 87.617 214.273 87.351C213.804 87.085 213.095 86.952 212.145 86.952H210.15V90.467H212.373C213.209 90.467 213.855 90.3403 214.311 90.087C214.767 89.821 214.995 89.346 214.995 88.662ZM213.931 83.19C213.931 82.6327 213.735 82.2337 213.342 81.993C212.962 81.7397 212.398 81.613 211.651 81.613H210.15V85.033H211.86C212.443 85.033 212.93 84.8937 213.323 84.615C213.728 84.3237 213.931 83.8487 213.931 83.19Z"
              fill="#177B57"
            />
            <path
              d="M222.487 92.5V87.332L217.984 79.58H221.214L224.007 84.767H224.045L226.61 79.58H229.536L225.242 87.332V92.5H222.487Z"
              fill="#177B57"
            />
            <path
              d="M247.053 88.852C247.053 89.5107 246.913 90.0743 246.635 90.543C246.356 90.999 245.976 91.3727 245.495 91.664C245.026 91.9553 244.481 92.1707 243.861 92.31C243.24 92.4367 242.581 92.5 241.885 92.5H236.489V79.58H241.144C241.752 79.58 242.341 79.637 242.911 79.751C243.493 79.8523 244 80.036 244.431 80.302C244.874 80.5553 245.229 80.891 245.495 81.309C245.761 81.7143 245.894 82.221 245.894 82.829C245.894 83.589 245.685 84.197 245.267 84.653C244.861 85.0963 244.317 85.4383 243.633 85.679V85.717C244.139 85.7803 244.602 85.888 245.02 86.04C245.438 86.1793 245.799 86.382 246.103 86.648C246.407 86.9013 246.641 87.2117 246.806 87.579C246.97 87.9337 247.053 88.358 247.053 88.852ZM244.089 88.662C244.089 88.054 243.848 87.617 243.367 87.351C242.898 87.085 242.189 86.952 241.239 86.952H239.244V90.467H241.467C242.303 90.467 242.949 90.3403 243.405 90.087C243.861 89.821 244.089 89.346 244.089 88.662ZM243.025 83.19C243.025 82.6327 242.828 82.2337 242.436 81.993C242.056 81.7397 241.492 81.613 240.745 81.613H239.244V85.033H240.954C241.536 85.033 242.024 84.8937 242.417 84.615C242.822 84.3237 243.025 83.8487 243.025 83.19Z"
              fill="#177B57"
            />
            <path
              d="M259.899 90.524C259.367 91.322 258.696 91.8983 257.885 92.253C257.074 92.595 256.15 92.766 255.111 92.766C254.123 92.766 253.236 92.614 252.451 92.31C251.666 91.9933 250.994 91.5437 250.437 90.961C249.892 90.3783 249.474 89.6753 249.183 88.852C248.892 88.0287 248.746 87.104 248.746 86.078C248.746 85.0267 248.892 84.083 249.183 83.247C249.474 82.411 249.892 81.7017 250.437 81.119C250.994 80.5363 251.659 80.093 252.432 79.789C253.217 79.4723 254.104 79.314 255.092 79.314C256.042 79.314 256.903 79.485 257.676 79.827C258.461 80.169 259.12 80.739 259.652 81.537L257.581 82.867C257.29 82.4617 256.941 82.1387 256.536 81.898C256.131 81.6573 255.624 81.537 255.016 81.537C253.952 81.537 253.135 81.917 252.565 82.677C251.995 83.437 251.71 84.5327 251.71 85.964C251.71 87.37 252.008 88.4847 252.603 89.308C253.211 90.1313 254.098 90.543 255.263 90.543C255.82 90.543 256.327 90.4353 256.783 90.22C257.239 90.0047 257.613 89.6753 257.904 89.232L259.899 90.524Z"
              fill="#177B57"
            />
            <path
              d="M272.358 91.474C271.572 91.93 270.787 92.2593 270.002 92.462C269.216 92.6647 268.323 92.766 267.323 92.766C266.309 92.766 265.404 92.614 264.606 92.31C263.808 91.9933 263.13 91.5437 262.573 90.961C262.015 90.3783 261.585 89.6753 261.281 88.852C260.989 88.016 260.844 87.085 260.844 86.059C260.844 85.0077 260.989 84.064 261.281 83.228C261.585 82.392 262.009 81.689 262.554 81.119C263.111 80.5363 263.789 80.093 264.587 79.789C265.385 79.4723 266.278 79.314 267.266 79.314C268.266 79.314 269.153 79.485 269.926 79.827C270.711 80.169 271.37 80.739 271.902 81.537L269.812 82.867C269.52 82.4617 269.172 82.1387 268.767 81.898C268.361 81.6573 267.829 81.537 267.171 81.537C266.043 81.537 265.201 81.9297 264.644 82.715C264.086 83.5003 263.808 84.5833 263.808 85.964C263.808 89.08 265.049 90.638 267.532 90.638C267.937 90.638 268.323 90.6127 268.691 90.562C269.071 90.4987 269.406 90.3847 269.698 90.22V88.035H267.095V85.85H272.358V91.474Z"
              fill="#177B57"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M298.454 3.4978H272.292V0.497803H301.454V29.4152H298.454V3.4978Z"
              fill="#177B57"
            />
          </svg> */}
        </div>
        <div className="banner-image">
          <img src={BannerImage} alt="banner" />
        </div>
      </div>
      <div className="banner-details">
        <h1 className="md">
          Your Guide to the
          <span className="md bold"> Global Steel Production Landscape</span>
        </h1>
        <p className="lead">
          Plantfacts is a web-based solution that provides in-depth information
          about the technical capabilities of steel producers along the complete
          steelmaking value chain.
        </p>
        <p className="lead">
          You can find answers to all your questions related to strategy,
          sourcing, marketing, or market intelligence here. Whether you are a
          steel supplier, steel buyer/consumer, plant manufacturer, raw material
          supplier or refractory material manufacturer, Plantfacts provides the
          right insight so you can make fact-based decisions.
        </p>
        <div className="mt-40">
          <Button handleClick={() => { }} />
        </div>
      </div>
    </div>
  );
};

export default Banner;
