import React, {useState} from "react";
import "./Testimonials.scss";
import usePagination from "hooks/usePagination";
import Pagination from "components/paginationButton/Pagination";

const testimonials = [
  {
    description:
      "“Plantfacts has become an indispensable tool for our team, offering precise insights into emerging steelmaking projects and capacities, aiding us in strategic planning and market analysis.”",
    name: "",
    company:
      "Head of Trade Policy, Markets and Statistics of a European flat steel producer",
  },
  {
    description:
      "“Plantfacts is the perfect tool for identifying potential targets for revamps, modifications, and relining. This is the basis for OEMs to prepare order acquisitions in times when green field projects are not available.”",
    name: "",
    company: "Former CTO of a leading OEM",
  },
  {
    description:
      "“Plantfacts is the perfect tool for identifying potential targets for revamps, modifications, and relining. This is the basis for OEMs to prepare order acquisitions in times when green field projects are not available.”",
    name: "",
    company: "Former CTO of a leading OEM",
  },
  {
    description:
      "“Utilizing Plantfacts, we can precisely pinpoint which plants need our expertise in modifications and maintenance, ensuring our services are timely and effectively meet the needs of our clients.”",
    name: "",
    company:
      "Vice President of a leading global metallurgical plant engineering company",
  },
  // {
  //   description:
  //     "I highly recommend Plantfacts to anyone seeking comprehensive market analysis. It's been invaluable to our business.",
  //   name: "Person 4",
  //   company: "Title4, Company4",
  // },
  // {
  //   description:
  //     "As a consultant, Plantfacts has become an essential tool in my arsenal. It streamlines my research process and impresses clients.",
  //   name: "Person 5",
  //   company: "Title5, Company5",
  // },
  // {
  //   description:
  //     "Plantfacts has exceeded our expectations. It's become an integral part of our decision-making process.",
  //   name: "Person 6",
  //   company: "Title6, Company6",
  // },
];

const Testimonials = () => {
  const {
    currentItems,
    currentPage,
    totalPages,
    handleClickPrevious,
    handleClickNext,
  } = usePagination(testimonials, 2);
  return (
    <div className="testimonials-container">
      <div className="container">
        <div className="title">
          <h2 className="md bold">Testimonials</h2>
          <p className="lead text-darkLight">
            What our clients say about Plantfacts
          </p>
        </div>
        <div
          className={`${
            currentItems.length === 1 ? "no-border" : "border-line"
          }`}
        ></div>
        <div className="testimonials-wrapper">
          {currentItems.map((testimonial, index) => (
            <div className="testimonial" key={index}>
              <div>
                <p className="description">{testimonial.description}</p>
                <div className="client-info">
                  <p className="sm font-16">{testimonial.name}</p>
                  <p className="sm font-16"> {testimonial.company}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleClickPrevious={handleClickPrevious}
          handleClickNext={handleClickNext}
        />
      </div>
    </div>
  );
};

export default Testimonials;
