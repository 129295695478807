import "./App.scss";
import Header from "components/header/Header";
import Banner from "components/banner/Banner";
import Glance from "components/glance/Glance";
import Videos from "components/videos/Videos";
import Footer from "components/footer/Footer";
import Contact from "components/contact/Contact";
import Testimonials from "components/testimonials/Testimonials";
import Guide from "components/guide/Guide";

function App() {
  return (
    <div className="App">
      <Header />
      <div>
        <div className="container">
          <Banner />
          <Glance />
        </div>
        <Videos />
        <Guide />
        <Testimonials />
        <Contact />
        <Footer />
      </div>
    </div>
  );
}

export default App;
