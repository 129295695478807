import React, {useState} from "react";
import demoGraphy from "assets/images/demography.png";
import Modal from "components/modal/Modal";
const Button = () => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      {open && <Modal handleClose={handleClick} />}
      <div className="btn-container">
        <button onClick={handleClick} className="btn btn-primary">
          <img src={demoGraphy} alt="demograpty icon" />
          <span>Request a demo</span>
        </button>
      </div>
    </>
  );
};

export default Button;
