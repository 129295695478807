import React from "react";
import book from "assets/images/ClosedBook.png";
import inventory from "assets/images/Inventory.png";
import notification from "assets/images/notification.png";
import capacity from "assets/images/capacity.png";
import Operating from "assets/images/operation.png";
import business from "assets/images/Business.png";
import "./Glance.scss";

const data = [
  {
    image: Operating,
    name: "14,000+",
    description: "Operating facilities",
  },
  {
    image: inventory,
    name: "1,000+",
    description: "Planned upcoming projects",
  },
  {
    image: business,
    name: "39",
    description:
      "Plant types, from raw material preparation to organic coating",
  },
  {
    image: capacity,
    name: "2.6Bt+",
    description: "of steelmaking capacity",
  },
  {
    image: book,
    name: "20 years",
    description: "Of historical data",
  },
  {
    image: notification,
    name: "Daily updates",
    description: "Data refreshed every day",
  },
];

const Glance = () => {
  return (
    <div className="glance-container">
      <div className="plant-detail">
        <h1 className="md">
          Plantfacts
          <span className="md bold"> at a Glance</span>
        </h1>
        <p className="lead">
          Plantfacts creates transparency about the capacities and technical
          setup of steel producers worldwide.
        </p>
      </div>
      <div className="plant-details-container grid grid-3">
        {data?.map((item) => (
          <div key={item.description} className="glance">
            <div className="image-card">
              <img src={item?.image} />
            </div>
            <div className="img-desc">
              <div className="title">
                <p className="lg text-primary">{item?.name}</p>
              </div>
              <div className="description">
                <h1 className="lead">{item?.description}</h1>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Glance;
