import React, { useState, useRef } from "react";
import closeIcon from "assets/images/close.png";
import "./Modal.scss";
import ReactDom from "react-dom";
import useOnClickOutside from "hooks/useOnClickOutside";

const Modal = ({ handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    job: "",
    company: "",
    message: "",
  });

  const [emailError, setEmailError] = useState("");
  const modalRef = useRef(null);
  useOnClickOutside(modalRef, () => {
    handleClose();
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSendEmail = () => {
    if (!validateEmail(formData.email)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    setEmailError("");
    const subject = encodeURIComponent("Demo Request");
    /* const body = encodeURIComponent(
      `Name: ${formData.name}%0D%0A` +
        `Email: ${formData.email}%0D%0A` +
        `Job: ${formData.job}%0D%0A` +
        `Company: ${formData.company}%0D%0A` +
        `Message%0D%0A: ${formData.message}`
    ); */
    const body = encodeURIComponent(`
      Name: ${formData.name}
      Email: ${formData.email}
      Job: ${formData.job}
      Company: ${formData.company}
  
      Message:
      ${formData.message.replace(/\n/g, "<br>")}
    
`);

    const mailtoLink = `mailto:Plantfacts@bcg.com?subject=${subject}&body=${body}`;
    window.open(mailtoLink, "_blank");
    handleClose();
  };
  const isFormEmpty = Object.values(formData).some((value) => value === "");

  return ReactDom.createPortal(
    <div className="backdrop">
      <div ref={modalRef} className="modal-container">
        <div className="bar">
          <p></p>
        </div>
        <div className="modal-wrapper">
          <div className="modal-header">
            <p className="header-text text-primary">Request a Demo</p>
            <button onClick={handleClose} className="closeIcon">
              <img src={closeIcon} alt="close Icon" width={30} height={30} />
            </button>
          </div>
          {emailError && (
            <div style={{ color: "red" }} className="error">
              {emailError}
            </div>
          )}{" "}
          <div className="form-container">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
            />

            <input
              type="text"
              name="job"
              value={formData.job}
              onChange={handleChange}
              placeholder="Job"
            />
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
              placeholder="Company"
            />
            <div className="text-area">
              <label htmlFor="message">Message</label>
              <textarea
                rows={3}
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter a description..."
              />
            </div>
            <div className="btn-wraper">
              <button
                className="btn btn-primary"
                onClick={handleSendEmail}
                disabled={isFormEmpty}
              >
                Submit Request
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default Modal;
