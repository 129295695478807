import React from "react";
import "./Pagination.scss";
import RightArrow from "assets/images/roundedRightArrow.png";
import LeftArrow from "assets/images/roundedLeftArrow.png";
import lightRightArrow from "assets/images/whiteArrowRight.png";
import lightLeftArrow from "assets/images/whiteArrowLeft.png";

const Pagination = ({
  currentPage,
  totalPages,
  handleClickPrevious,
  handleClickNext,
  isLight = false,
}) => {
  return (
    <div className="pagination-container">
      <button
        className="btn btn-pagination"
        onClick={handleClickPrevious}
        disabled={currentPage === 1}
      >
        <img src={isLight ? lightLeftArrow : LeftArrow} />
      </button>
      <span className="page-number">
        <span className={`active-page ${isLight ? "text-light" : ""}`}>
          {currentPage}
        </span>
        <span className="total-pages">/{totalPages}</span>
      </span>
      <button
        className="btn btn-pagination"
        onClick={handleClickNext}
        disabled={currentPage === totalPages}
      >
        <img src={isLight ? lightRightArrow : RightArrow} />
      </button>
    </div>
  );
};

export default Pagination;
